import artemBoichenko from '../../assets/team/artem-boychenko.jpg'
import artemBoichenkosmall from '../../assets/team/artem-boychenk-small.jpg'
import valentinaLysenko from '../../assets/team/valentina-lysenko.jpg'
import valentinaLysenkosmall from '../../assets/team/valentina-lysenko-small.jpg'
import tamaraMikhailova from '../../assets/team/tamara-mikhailova.jpg'
import tamaraMikhailovasmall from '../../assets/team/tamara-mikhailova-small.jpg'
import irynaShevchenko from '../../assets/team/iryna-shevchenko.jpg'
import irynaShevchenkosmall from '../../assets/team/iryna-shevchenko-small.jpg'
import adrianSobolevsky from '../../assets/team/adrian-sobolevsky.jpg'
import adrianSobolevskysmall from '../../assets/team/adrian-sobolevsk-small.jpg'
import halynaYakovleva from '../../assets/team/halyna-yakovleva.jpg'
import halynaYakovlevasmall from '../../assets/team/halyna-yakovleva-small.jpg'
import juliaSmolyak from '../../assets/team/julia-smolyak.jpg'
import juliaSmolyaksmall from '../../assets/team/julia-smolyak-small.jpg'
import katerynaMurashko from '../../assets/team/kateryna-murashko.jpg'
import katerynaMurashkosmall from '../../assets/team/kateryna-murashko-small.jpg'
import anastasiaKhomych from '../../assets/team/anastasia-khomych.jpg'
import anastasiaKhomychsmall from '../../assets/team/anastasia-khomych-small.jpg'


export const team = [
    {
        id: 1,
        key: 'artem_boichenko',
        image: artemBoichenko,
        imagesmall: artemBoichenkosmall
    },
    {
        id: 2,
        key: 'iryna_shevchenko',
        image: irynaShevchenko,
        imagesmall: irynaShevchenkosmall
    },
    {
        id: 3,
        key: 'adrian_sobolevsky',
        image: adrianSobolevsky,
        imagesmall: adrianSobolevskysmall
    },
    {
        id: 4,
        key: 'valentina_lysenko',
        image: valentinaLysenko,
        imagesmall: valentinaLysenkosmall
    },
    {
        id: 5,
        key: 'tamara_mikhailova',
        image: tamaraMikhailova,
        imagesmall: tamaraMikhailovasmall
    },
    {
        id: 6,
        key: 'halyna_yakovleva',
        image: halynaYakovleva,
        imagesmall: halynaYakovlevasmall
    },
    {
        id: 7,
        key: 'julia_smolyak',
        image: juliaSmolyak,
        imagesmall: juliaSmolyaksmall
    },
    {
        id: 8,
        key: 'kateryna_murashko',
        image: katerynaMurashko,
        imagesmall: katerynaMurashkosmall
    },
    {
        id: 9,
        key: 'anastasia_khomych',
        image: anastasiaKhomych,
        imagesmall: anastasiaKhomychsmall
    },
]
